.loader {
    width: 40px;
    background: linear-gradient(to bottom right, #0000 calc(50% - 50px), #fff 50%, #0000 calc(50% + 40px)) bottom right/calc(200% + 80px) calc(200% + 80px) #f4bf5d96;
    animation: l8 1.5s infinite;
}

@keyframes l8 {
    100% {
        background-position: top left
    }
}