html,
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  min-width: 50rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button:focus {
  outline: none;
}

.quill-better-table {
  min-width: 300px !important;
  width: 50% !important;
}

@media (max-width: 1400px) {
  html,
  body {
    font-size: 14px;
  }
}
